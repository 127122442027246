import * as React from "react";
import * as styles from "./privacy-policy.module.css";

import { Button, IconButton } from "@chakra-ui/react";
import { FunctionComponent, useCallback, useState } from "react";

import { HamburgerIcon } from "@chakra-ui/icons";
import Layout from "../components/Base/Layout";
import NavigationMobile from "../components/Header/navigation-mobile";
import PortalDrawer from "../components/Header/portal-drawer";
import { SEO } from "../components/Base/Seo";
import { navigate } from "gatsby";

const PrivacyPolicy: FunctionComponent = () => {


  return (
    <Layout>
      <div className={styles.privacyPolicy}>

        <div className={styles.docTemplate}>
          <div className={styles.textColumn}>
            <div className={styles.subtitleLarge}>
              <b className={styles.display}>CDN HEALTH</b>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>Privacy Policy</div>
            </div>
            <div className={styles.textbody}>

              <div className={styles.p1}>
                <div className={styles.caption}>
                  This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information when you use our services. It also informs you of your privacy rights and how the law protects you.
                </div>
              </div>
              <div className={styles.p2}>
                <div className={styles.caption}>
                  {`We use your personal data to enhance and provide the service. By using the service, you agree to the collection and use of information in accordance with this Privacy Policy. `}
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                Interpretation and Definitions
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>
                    <b>Interpretation</b>
                  </p>
                  <p className={styles.interpretation}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.theWordsOfWhichTheInitial}>
                    <span>
                      The capitalized words have defined meanings as stated below. These definitions apply regardless of appearing in singular or plural form.
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>
                    <b>Definitions</b>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Account: `}</b>
                    <span>
                      A unique account created to access our service.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Company: `}</b>
                    <span>
                      CDN Health also referred to as "the Company", "We", "Us", or "Our".
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Cookies: `}</b>
                    <span>
                      Small files placed on your device by websites, containing browsing history details.

                    </span>
                  </ul>
                </div>
              </div>
            </div>

            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Device: `}</b>
                    <span>
                      Any device like a computer, cellphone, or tablet that accesses the service.

                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Personal Data: `}</b>
                    <span>
                      Information relating to an identified or identifiable individual.

                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Service: `}</b>
                    <span>The official website accessible from cdnhealth.net.</span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Service Provider: `}</b>
                    <span>
                      Person or entity processing data on the company's behalf.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Usage Data: `}</b>
                    <span>
                      Automatically collected data from service use or service infrastructure.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`Website `}</b>
                    <span>
                      cdnhealth.net.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <ul className={styles.accountMeansAUniqueAccount}>
                    <b className={styles.account}>{`You: `}</b>
                    <span>
                      The individual or entity accessing or using the service.
                    </span>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.display1}>
                Collecting and Using Your Personal Data
              </div>
            </div>
            <div className={styles.subtitle3}>
              <div className={styles.display1}>Types of Data Collected</div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.p}>
                <div className={styles.caption}>

                  <p className={styles.interpretation}>
                    <span>
                      Personal data including email address, first and last name, and usage data may be requested by us during service use. We do not receive information from third parties.
                    </span>
                  </p>
                  <p className={styles.interpretation}>
                    <span>
                      Data processing purposes include providing, improving, and administering services, communication, security, fraud prevention, and legal compliance.                    </span>
                  </p>

                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>
                    <b>Usage Data</b> encompasses information such as device IP address, browser details, page visit timestamps, device identifiers, and other diagnostic data.
                  </p>
                  <p className={styles.blankLine2}>
                    <span>{` `}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption18}>
                  <p className={styles.interpretation}>
                    <b>Tracking Technologies and Cookies</b>
                  </p>
                  <p className={styles.interpretation}>
                    <span>&nbsp;</span>
                  </p>
                  <p className={styles.theWordsOfWhichTheInitial}>
                    <span>
                      Cookies and tracking technologies like beacons, tags, and scripts are utilized for service activity tracking and information storage. Cookies types include session, persistent, necessary, notice acceptance, and functionality cookies.
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>
                    <b>Use of Your Personal Data</b>
                  </p>
                  <p className={styles.blankLine2}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.theWordsOfWhichTheInitial}>
                    <span>
                      The company may use personal data for service maintenance, managing accounts, contract performance, communication, providing news and offers, managing requests, business transfers, and other analytical purposes.
                    </span>
                  </p>
                  <p className={styles.theWordsOfWhichTheInitial}>
                    <span>
                      We may share personal information under specific situations like service providers' involvement, business transfers, with affiliates or business partners, and with user consent.
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>
                    <b>Retention and Transfer of Your Personal Data
                    </b>
                  </p>
                  <p className={styles.blankLine2}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.theWordsOfWhichTheInitial}>
                    <span>
                      We retain personal data for as long as necessary and ensure secure data treatment during transfers or disclosures.
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.textbody}>
              <div className={styles.textbody14}>
                <div className={styles.p}>
                  <div className={styles.caption}>
                    <p className={styles.interpretation}>
                      <b>Disclosure of Your Personal Data
                      </b>
                    </p>
                    <p className={styles.interpretation}>
                      <span>&nbsp;</span>
                    </p>
                    <p className={styles.interpretation}>
                      <span>Personal Data may be disclosed during business transactions or as required by law enforcement or other legal obligations.</span>
                    </p>

                  </div>
                </div>
              </div>
              <div className={styles.textbody14}>
                <div className={styles.p}>
                  <div className={styles.caption}>
                    <p className={styles.interpretation}>
                      <b>Children's Privacy</b>
                    </p>
                    <p className={styles.interpretation}>
                      <span>&nbsp;</span>
                    </p>
                    <p className={styles.interpretation}>
                      <span>Our service is not for users under 18 years old, and we do not knowingly collect information from them.</span>
                    </p>

                  </div>
                </div>
              </div>
              <div  >
                <div className={styles.p}>
                  <div className={styles.caption}>
                    <p className={styles.interpretation}>
                      <b>Links to Other Websites
                      </b>
                    </p>
                    <p className={styles.interpretation}>
                      <span>&nbsp;</span>
                    </p>
                    <p className={styles.interpretation}>
                      <span>The service may contain links to third-party websites with different privacy policies. We advise reviewing the privacy policies of any visited site.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.textbody14}>
              <div className={styles.p}>
                <div className={styles.caption}>
                  <p className={styles.interpretation}>
                    <b>Changes to this Privacy Policy</b>
                  </p>
                  <p className={styles.blankLine2}>
                    <b>&nbsp;</b>
                  </p>
                  <p className={styles.theWordsOfWhichTheInitial}>
                    <span>
                      The policy may be updated periodically, and you will be notified about changes before they come into effect.
                    </span>
                  </p>

                  <p className={styles.theWordsOfWhichTheInitial}>
                    <span>
                      If you have inquiries, contact us at <a style={{ color: "#0079C1" }} href="mailto:info@cdnhealth.net"> info@cdnhealth.net. </a>                    </span>
                  </p>
                </div>
              </div>
            </div>


          </div>
        </div>

      </div>

    </Layout>
  );
};

export default PrivacyPolicy;

export const Head = () => (
  <SEO title="CDN Health" description="Privacy Policy" />
)
